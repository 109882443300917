var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ExpertisesProposedList.length == 0
    ? _c("h2", { staticStyle: { "text-align": "center" } }, [
        _vm._v(" No Expertises proposed to review "),
      ])
    : _c("pga-expertises-proposed-list", {
        attrs: { "expertises-proposed-list": _vm.ExpertisesProposedList },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }