var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditMyExpertises" } },
            [
              _c("pga-edit-button", {
                attrs: { text: "Add-Edit" },
                on: { click: _vm.selectExpertises },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { cols: "12" } },
        [
          _c("pga-data-table", {
            attrs: {
              title: "Expertises",
              headers: _vm.headers,
              data: _vm.myExpertises.Expertises,
              loading: _vm.isLoading,
              "col-props": ["Skill", "Status"],
            },
            on: { "row-clicked": _vm.rowClicked },
            scopedSlots: _vm._u([
              {
                key: "column-Skill",
                fn: function (props) {
                  return [
                    _c("pga-progress-linear", {
                      attrs: {
                        height: "20",
                        value: (props.row.item.Skill * 100) / 3,
                        color: _vm.color(props.row.item.Skill),
                        stripped: true,
                        content: ["Basic", "Intermediate", "Advanced"],
                      },
                    }),
                  ]
                },
              },
              {
                key: "column-Status",
                fn: function (props) {
                  return [
                    _c("pga-status-skill", {
                      model: {
                        value: props.row.item.Status,
                        callback: function ($$v) {
                          _vm.$set(props.row.item, "Status", $$v)
                        },
                        expression: "props.row.item.Status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }