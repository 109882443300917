var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Expertises",
      headers: _vm.headers,
      data: _vm.listExpertises,
      loading: _vm.isLoading,
      "item-key": "Id",
      "col-props": ["Skill"],
      "show-select": "",
    },
    scopedSlots: _vm._u([
      {
        key: "column-Skill",
        fn: function (props) {
          return [
            _vm.showSkill(props.row.item.Id)
              ? _c("pga-slider", {
                  attrs: {
                    value: _vm.getSkill(props.row.item.Id),
                    id: props.row.item.Id,
                    min: 1,
                    max: 3,
                    step: 1,
                    tickSize: 3,
                    "error-key": "ExpertiseIds",
                    "error-message-key": "Expertises",
                    "tick-labels": ["Basic", "Intermediate", "Advanced"],
                    "tick-labels-alternative": ["B", "I", "A"],
                    errors: _vm.Errors,
                  },
                  on: { "change-skill": _vm.setSkill, input: _vm.setSkill },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedMyExpertises,
      callback: function ($$v) {
        _vm.selectedMyExpertises = $$v
      },
      expression: "selectedMyExpertises",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }